import { useTranslation } from "react-i18next";

import Image404 from "~/assets/images/404.svg";
import Image500 from "~/assets/images/500.svg";
import Image from "~/components/atoms/Image";
import Typo from "~/components/atoms/Typo";
import errorConfig from "~/utilities/config/errorConfig";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SectionErrorProps {
  error?: Error | any;
  errorCode: string;
}

const SectionError: React.FC<SectionErrorProps> = ({ error, errorCode }) => {
  const { t } = useTranslation();

  const errorImage = errorCode === "404" ? Image404 : Image500;

  return (
    <div className="mb-6 mt-[80px] flex flex-col items-center justify-center lg:mt-12">
      <Image
        isLocalImage
        url={errorImage}
        className="max-h-[343px] max-w-[586px]"
      />
      <div className="flex flex-col items-center justify-center gap-3">
        <Typo
          tag={ETypoTag.H1}
          color={ETypoColor.TEXT}
          variant={ETypoVariant.HEADER_32}
          className="font-extrabold opacity-50"
        >
          {error?.statusText ?? t(errorConfig[errorCode].description || "")}
        </Typo>
        <Typo
          tag={ETypoTag.P}
          color={ETypoColor.TEXT}
          variant={ETypoVariant.BODY_TITLE_16}
          className="opacity-50"
        >
          {error?.data ?? t(errorConfig[errorCode].message || "")}
        </Typo>
      </div>
    </div>
  );
};

export default SectionError;
